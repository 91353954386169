import React, { forwardRef, ReactNode } from "react";
import { TeliaIcon } from "@telia/b2x-telia-icon";
import { arrowRight } from "@teliads/icons";
import classnames from "classnames/bind";

import styles from "./b2x-telia-cta-link.module.scss";

const rootClassName = "b2x-telia-cta-link";
const cx = classnames.bind(styles);
export const CTA_LINK_VARIANT = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  EXPRESSIVE: "expressive",
  PRIMARY_WHITE: "primary-white",
  SECONDARY_WHITE: "secondary-white",
  EXPRESSIVE_WHITE: "expressive-white",
} as const;

export const ctaLinkVariants = Object.values(CTA_LINK_VARIANT);
export type CtaLinkVariant = typeof CTA_LINK_VARIANT[keyof typeof CTA_LINK_VARIANT];

export const CTA_LINK_SIZE = {
  SMALL: "sm",
  MEDIUM: "md",
  LARGE: "lg",
} as const;
export const ctaLinkSizes = Object.values(CTA_LINK_SIZE);
export type CtaLinkSize = typeof CTA_LINK_SIZE[keyof typeof CTA_LINK_SIZE];

type Props = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  children?: ReactNode;
  ["data-testid"]?: string;
  size?: CtaLinkSize;
  variant?: CtaLinkVariant;
  allyLabel?: string;
  className?: string;
  fullWidth?: boolean;
};

const TeliaCtaLinkComponent = <T extends HTMLAnchorElement>(
  {
    children,
    size = CTA_LINK_SIZE.MEDIUM,
    variant = CTA_LINK_VARIANT.PRIMARY,
    fullWidth = false,
    className,
    ["data-testid"]: dataTestId,
    allyLabel,
    ...props
  }: Props,
  ref: React.ForwardedRef<T>
) => (
  <a
    {...props}
    data-testid={dataTestId}
    className={cx(className, {
      [rootClassName]: true,
      [`${rootClassName}--${variant}`]: !!variant,
      [`${rootClassName}--full-width`]: fullWidth,
      [`${rootClassName}--${size}`]: true,
      [`${rootClassName}--icon-only`]: !children,
    })}
    ref={ref}
  >
    {children}
    <TeliaIcon {...(allyLabel ? { allyTitle: allyLabel } : {})} svg={arrowRight.svg} size="sm" />
  </a>
);

export const TeliaCtaLink = forwardRef(TeliaCtaLinkComponent);
TeliaCtaLink.displayName = "TeliaCtaLink";
